<template>
  <ion-card class="blab-card p-2 m-0 clickable-item" @click="openBlabDetails(post.id)">
    <div class="" style="position: absolute; z-index: 2; left: 8px">
      <ion-badge class="badge" :style="`color: ${getBadgeColor}; border: 2px solid ${getBadgeColor}`">
        {{ blabCategories[post.category].name }}
      </ion-badge>
    </div>
    <div v-if="extractImages.length" class="" style="position: absolute; z-index: 2; right: 9px">
      <ion-badge class="" style="font-size: 13px">
        <ion-icon class="mr-1" style="color: white" :icon="copyOutline"></ion-icon>
        {{ extractImages.length }}
      </ion-badge>
    </div>
    <div :class="extractImages.length ? '' : 'mt-4'">
      <div v-if="extractImages.length" @click.stop>
        <ClientOnly>
          <swiper
            :slides-per-view="1"
            :modules="[SwiperNavigation, SwiperPagination]"
            navigation
            :pagination="{ clickable: true }"
            class="visuals"
          >
            <swiper-slide v-for="(visual, index) in extractImages" :key="`vis-${index}`">
              <div class="d-flex justify-content-center">
                <img
                  v-image
                  style="width: 100%; height: auto; border-radius: 12px"
                  :src="resizeUpload(visual, '380h')"
                  @click="openBlabDetails(post.id)"
                />
              </div>
            </swiper-slide>
            ...
          </swiper>
        </ClientOnly>
      </div>
    </div>
    <div class="flex-grow-1">
      <div class="title text-black d-flex align-items-center justify-content-start">
        {{ post.title }}
      </div>
      <p>
        {{ truncateText(stripHtmlTags(post.description), 150) }}
      </p>
    </div>

    <div class="mt-2 d-flex align-items-center justify-content-center">
      <div class="actions d-flex">
        <a href="#" @click.stop>
          <Reaction
            type="post"
            :reactions="reactions"
            :user-reaction="userReaction"
            :left-indent-popup="!indentReactionRight"
            :right-indent-popup="indentReactionRight"
            class="rxn"
            @changed="(reaction) => reactionChanged(reaction.key, post, reaction.isInstant)"
          >
            <ion-button
              color="transparent"
              class="inline-button icon-button clickable text-primary"
              :class="{
                'text-secondary': !!userReaction && userReaction.reaction,
              }"
            >
              <div class="d-flex align-items-center justify-content-center">
                <i class="ti-thumb-up mr-2 icon" />
                <span class="reaction-count">{{ reactions.total_count || 0 }}</span>
              </div>
            </ion-button>
          </Reaction>
        </a>
      </div>

      <div v-if="repliesCount" class="d-flex align-items-center">
        <ion-icon style="" class="d-inline-block comment-icon ml-2 mr-1" :icon="chatboxEllipsesOutline" />{{
          repliesCount
        }}
      </div>

      <div class="ml-auto" @click.stop>
        <router-link
          :to="{
            name: 'profile',
            params: { username: get(post, 'user.username') },
          }"
          >@{{ get(post, 'user.username') }}
        </router-link>
      </div>
    </div>
  </ion-card>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { resizeUpload } from '@/shared/utils/upload';
import { Prop } from 'vue-property-decorator';
import { chatboxEllipsesOutline, copyOutline } from 'ionicons/icons';
import BlabDetailsModal from '@/shared/modals/BlabDetailsModal.vue';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import { stripHtmlTags } from '@/shared/utils/string';
import store from '@/shared/store';
import { authStore } from '@/shared/pinia-store/auth';
import constants from '@/shared/statics/constants';
import { truncateText } from '@/shared/utils/string';
import Reaction from '@/shared/components/Reaction/index.vue';

@Options({
  name: 'BlabCard',
  components: { Reaction, BlabDetailsModal },
})
export default class BlabCard extends Vue {
  @Prop({ default: () => ({}) }) post!: any;
  @Prop({ default: () => ({}) }) repliesCount!: any;
  @Prop({ default: false }) indentReactionRight!: boolean;
  @Prop({ default: false }) openBlank!: boolean;

  public first = first;
  public openBlabAsModal = false;
  public blabid = '';
  get = get;
  formatTimeAgo = formatTimeAgo;
  stripHtmlTags = stripHtmlTags;
  resizeUpload = resizeUpload;
  truncateText = truncateText;

  public blabCategories = keyBy(constants.blabCategories, 'value');
  public chatboxEllipsesOutline = chatboxEllipsesOutline;
  public copyOutline = copyOutline;
  public get getBadgeColor() {
    return this.blabCategories[this.post.category].color;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  setup() {
    const slideOpts = {
      initialSlide: 1,
      speed: 400,
    };
    return { slideOpts };
  }

  public get extractImages() {
    let imgSrc;
    const imgUrls = [];
    const rex = /<img[^>]+src="(https:\/\/[^">]+)"/g;

    while ((imgSrc = rex.exec(this.post.description))) {
      imgUrls.push(imgSrc[1]);
    }

    return imgUrls;
  }

  public showBlabs() {
    const router = useRouter();
    router.push({ name: 'posts-tabs' });
  }

  public get userReaction() {
    if (this.post.user_reaction) {
      return this.post.user_reaction;
    }
  }

  public get visuals() {
    return this.post.visuals;
  }

  public async reactionChanged(reaction: string, post: any, isInstant = false) {
    const reactionResp = await store.dispatch('BlabsModule/react', {
      reaction,
      blab: post,
      isInstant,
    });
    this.$emit('afterReaction', reactionResp);
  }

  public get reactions() {
    const { reaction_counts } = this.post || {};
    return reaction_counts;
  }

  public openBlabDetails(id: string) {
    this.$emit('open', id);
  }

  mounted() {}
}
</script>

<style lang="sass" scoped>
.inside
  padding: 7px
.title
  color: #214163
  font-size: 18px
  font-weight: bold
  overflow-wrap: anywhere
@media(max-width: 419px)
  .title.title
    font-size: 16px
.description
  font-size: 14px
.icon
  font-size: 16px
  font-weight: bold
  margin-bottom: 3px
.reply
  font-size: 12px
.img
  min-width: 50px
  width: 50px !important
  height: 50px !important
  border-radius: 30px
  border: solid gray 0.1px
  object-fit: cover

.visuals
  width: 100%
  height: auto
.reaction-count
  font-size: 14px
  font-weight: bold

.blab-card
  width: 100%
  .username-text
    word-break: break-all
  .blab-title
    font-size: 16px
    font-weight: bold
  .img
    min-width: 50px
    object-fit: cover

.blabs-list::-webkit-scrollbar
  display: none
.blabs-list
  -ms-overflow-style: none
  scrollbar-width: none
.rxn
  top: 3px

ion-badge
  display: flex
  justify-content: center
  align-items: center
  background: #09183c

  min-width: 30px
  top: 0px
  border-radius: 12px

  padding-top: 4px
  padding-bottom: 4px
  padding-left: 12px
  padding-right: 12px
  font-size: 10px

.comment-icon
  font-size: 17px
  color: grey
  margin-top: 3px
</style>
